import React from "react";
import { Layout } from "../components/layout";
import { usePasteurPage } from "../services/firestore";
import { graphql } from "gatsby";


const Pasteur = ({pasteur}) => {
  return <>
    <div className=" flex flex-col py-4 lg:px-4">
      <img src={pasteur.image.src} alt={pasteur.image.image} className=" w-full lg:h-[492px]	object-cover lg:rounded-lg"/>
      <div className="px-4">
        <h2 className="text-2xl mt-2 font-bold">{pasteur.title}</h2>
        <h3 className="opacity-80 text-sm mb-4">{pasteur.subTitle}</h3>
        <p className="whitespace-pre-wrap">{pasteur.description}</p>
      </div>
    </div>
  </>
}

const PasteurLayout = ({pasteurs}) => {
  return <>
    <div className="lg:grid lg:grid-cols-2 my-12">
      {pasteurs.map( (pasteur) => <div> <Pasteur pasteur={pasteur} /></div>)}
    </div>
  </>
}


const PasteursPage = ({data}) => {
  const page = usePasteurPage(data.allPasteurPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Pasteurs"}>
      <div class="container mx-auto">
        { page && <PasteurLayout pasteurs={page.pasteurs}/>}
      </div>
    </Layout>
  );
};


export const query = graphql`
query {
  allPasteurPages {
    nodes {
      title
      heroBannerType
      heroBannerImage {
        image
        src
      }
      pasteurs {
        image {
          image
          src
        }
        description
        title
        subTitle
      }
    }
  }
}
`;

export default PasteursPage;

